import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "npm"
    }}>{`NPM`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-cmd"
      }}>{`npm i unnamed
`}</code></pre>
    <h3 {...{
      "id": "yarn"
    }}>{`Yarn`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-cmd"
      }}>{`yarn add unnamed
`}</code></pre>
    <h3 {...{
      "id": "cdn"
    }}>{`CDN`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link rel="stylesheet" href="https://unpkg.com/unnamed" />
`}</code></pre>
    <p>{`Or customize a repository for landing pages`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cmd"
      }}>{`git clone https://github.com/smakosh/Unnamed-getting-started.git
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      